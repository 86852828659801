/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .home5-head {
        position: absolute;
        font-size: 6.5em;
        width: 100%;
        text-align: center;
        letter-spacing: -2px;
        z-index: 10;
        top: 25vw;
        font-weight: 800;
        line-height: 0.9em;
        /* text-shadow: 2px 1px 1px rgb(0, 0, 0); */
    }

    .home5-panel1 {
        position: relative;
        height: 120vw;
    }

    .home5-panel1 p {
        position: absolute;
        font-size: 1.8em;
        top: 84vw;
        text-align: right;
        width: 80vw;
        right: 2em;
        font-weight: 600;
        text-shadow: 2px 1px 1px rgb(0, 0, 0);
        line-height: 1em;
    }

    .home5-panel1 img {
        display: block;
        width: 100vw;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        z-index: 0;
        object-fit: cover;
        object-position: center;
        height: 100%;
    }
    .home5-panel2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.6em;
        padding: 0.6em;
    }
    .home5-panel2-img {
        height: 400px;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    .home5-panel2-text {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0.5em 0;
        font-size: 1.4em;
        line-height: 1em;

    }
    .home5-panel2-text button {
        font-size: 1em;
    }
}

@media only screen and (min-width: 600px) {
    .home5-head {
        top: 30vw;
        font-size: 7.5em;
        letter-spacing: -3px;
    }
    
    .home5-panel1 {
        height: 80vw;
    }
    .home5-panel1 p {
        top: 10vw;
        font-size: 2em;
    }
    .home5-panel2 {
        
        margin: 0 auto;
    }
}

@media only screen and (min-width: 800px) {
   
    .home5-head {
        font-size: 9em;
        top: 28vw;
    }
    .home5-panel1 p {
        top: 10vw;
        width: 60vw;
        right: 15vw;
        font-size: 2.2em;
    }
    .home5-panel1 {
        height: 70vw;
    }
    .home5-panel2 {
        grid-template-columns: 1fr 1fr;
        gap: 1em;
    }
}

@media only screen and (min-width: 1000px) {
    .home5-panel1 {
        height: 55vw;
    }
    .home5-head {
        font-size: 9em;
        top: 22vw;
    }
    .home5-panel1 p {
        font-size: 2.2em;
    }
    
    .home5-panel2-text {
        
    }
}

@media only screen and (min-width: 1200px) {
    .home5-head {
        top: 18vw;
        font-size: 14em;
        letter-spacing: -0.05em;
    }
    .home5-panel1 p {
        top: 6vw;
        font-size: 2.8em;
    }
    .home5-panel1 {
        height: 85vh;
    }
}