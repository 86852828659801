/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .blogpost {
        padding: 1em;
    }

    .blog-back {
        font-size: 1.6em;
        cursor: pointer;
    }

    .blog-back-text {
        font-size: 1.3em;
        font-weight: 400;
        cursor: pointer;
    }


    .blogpost-wrap p {
        font-weight: 300;
        padding: 1em 0;
    }

    .blogpost-wrap img {
        width: 100%;
        object-fit: cover;
        object-position: top;
    }

    .blogpost-wrap h3 {
        font-weight: 200;
        padding: 1em;
        font-size: 1.5em;
        border-left: 3px solid #111;
        margin: 1em 0;
    }
    .blogpost-author {
        font-size: 1.2em;
        text-align: right;
    }
    .blogpost-date {
        text-align: right;
        color: gray;
        font-size: 0.9em;
    }
}

@media only screen and (min-width: 600px) {
    .blogpost-wrap {
        width: 80vw;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 800px) {
    .blogpost-wrap {
        width: 70vw;
    }
    .blogpost-wrap p {
        font-size: 1.2em;
    }
    .blogpost-wrap img {
        max-height: 60vh;
    }
}

@media only screen and (min-width: 1000px) {
    .blogpost-wrap {
        width: 60vw;
    }
}

@media only screen and (min-width: 1200px) {
    .blogpost-wrap {
        width: 50vw;
    }
}