/* FONTS
    font-family: 'Roboto', sans-serif;
*/
/* COLORS
    Light Blue - 57b4ee
    Blue - 5783d6
    Yellow - F4E04D
    Lighter Blue - 54F2F2
    Dark - #0A0A0A
    Light - #FEFEFE
*/

@media only screen and (min-width: 350px) {
    .menu {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.6em;
    }

    .menu-wrap {
        gap: 0;
        padding: 0.4em 0;
    }

    .menu-cat {
        padding: 0.2em;
        font-size: 1.4em;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .menu-item {
        padding: 0.2em;
    }

    .menu-item-top {
        display: flex;
        justify-content: space-between;
    }

    .menu-item-name {
        font-size: 1.1em;
        font-weight: 400;
    }

    .menu-item-prices {
        display: flex;
        gap: 0.5em;
        height: fit-content;
    }

    .menu-item-price-block {
        display: grid;
        grid-template-columns: 1fr;
    }

    .menu-item-price {
        font-size: 1.2em;
    }

    .menu-item-size {
        font-size: 0.9em;
        font-weight: 400;
    }

    .menu-item-desc {
        font-size: 0.9em;
        font-weight: 300;
    }

    .menu-img {
        width: 100%;
    }
}

@media only screen and (min-width: 600px) {
    .menu-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 0.8em;
    }
}

@media only screen and (min-width: 800px) {
    .menu {
        width: 90%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1000px) {
    .menu {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 3fr;
    }

    .menu-right {
        display: block;
        overflow-y: scroll;
        height: 85vh;
        padding: 0.3em;
    }

    .menu-img {
        object-fit: cover;
        object-position: bottom;
        height: 80vh;
    }

    .menu-cat {
        font-size: 1.6em;
        padding: 0.2em 0.5em;
    }
}

@media only screen and (min-width: 1200px) {
    .menu-right {
        width: 90%;
        margin: 0 auto;
    }

    .menu-item {
    }
}