/* FONTS
    font-family: 'Poppins', sans-serif;
*/
/* COLORS
    Dark Green - #0B2E23
    Green - #115542
    Neon - #D0FC4A
*/

@media only screen and (min-width: 350px) {
    .nav-body {
        height: 100%;
        width: 100vw;
        position: fixed;
        z-index: 100;
        top: 0;
        left: 0;
        overflow-x: hidden;
        transition: 0.5s;
    }

    .nav-icon {
        font-size: 2em;
    }

    .nav-top {
        display: flex;
        justify-content: space-between;
        padding: 1em;
    }

    .nav-top img {
        width: 40vw;
    }

    .nav-links {
        display: grid;
        grid-template-columns: 1fr;
        /* padding: 1em; */
        margin-top: 3vh;
        overflow-y: scroll;
        padding-bottom: 2em;
    }

    .nav-link {
        font-size: 2em;
        font-weight: 500;
        text-decoration: none;
        padding: 0;
        margin: 0;
        width: fit-content;
        padding-left: 1em;
    }

    .copy {
        font-size: 0.8em;
        font-weight: 300;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 1em;
        text-align: center;
    }
    .nav-option-icon {
        font-size: 1.8em;
    }
    .nav-options {

    }
    .nav-option-link {
        font-size: 1.1em;
        text-decoration: none;
        padding-left: 1em;
        display: block;
    }
}

@media only screen and (min-width: 600px) {
    .nav-body img {
        width: 25vw;
    }

    .nav-icon {
        font-size: 1.6em;
    }

    .nav-links {
        margin-top: 6vh;
    }

    .nav-link {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 800px) {
    .nav-body img {
        width: 19vw;
    }

    .nav-links {
        margin-top: 6vh;
    }
}

@media only screen and (min-width: 1000px) {
    .nav-body img {
        width: 16vw;
    }
    .nav-links {
        margin-top: 8vh;
    }
}

@media only screen and (min-width: 1200px) {
    .nav-body img {
        width: 11vw;
    }

    .nav-links {
        margin-top: 6vh;
        font-size: 1.2em;
    }
}